import Papa from 'papaparse';

const csvConvertKey = (text: string) => {
  let convertedKey: string = text[0].toUpperCase();

  for (let i = 1; i < text.length; i++) {
    if (text[i] === '.') {
      convertedKey += ` - ${text[i + 1].toUpperCase()}`;
      i++;
      continue;
    }
    // put a space before
    else if (text[i] === text[i].toUpperCase()) {
      convertedKey += ` ${text[i]}`;
    } else {
      convertedKey += text[i];
    }
  }
  return convertedKey;
};

const csvParseAndDownload = (
  data: object[],
  columnsToShow: string[],
  fileName = 'leads'
) => {
  const theDate = new Date();
  let arrayToParseToCsv: any = data;

  arrayToParseToCsv.map((item: any) => {
    Object.keys(item).forEach((key) => {
      //remove __typename keys
      if (key.endsWith('__typename')) {
        delete item[key];
      } else {
        //checking if the column should be shown or not
        if (!columnsToShow.includes(key.split('.')[0])) {
          delete item[key];
          // console.log(key.split('.')[0], 'is not included in the column to show');
        }
        //format keys
        else {
          delete Object.assign(item, { [csvConvertKey(key)]: item[key] })[key];
        }
      }
    });
  });

  const csv = Papa.unparse(arrayToParseToCsv, {
    header: true,
  });
  const blob = new Blob([csv]);
  const a = window.document.createElement('a');

  a.href = window.URL.createObjectURL(blob);
  a.download = `${fileName} - ${theDate.toLocaleTimeString()}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export { csvParseAndDownload, csvConvertKey };
