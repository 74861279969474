import { ILogin } from '../pages/login';

const TOKEN_KEY = 'partnerLeads';

export const login = (credentials: ILogin) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(credentials));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }

  return false;
};

export const loadCredentials = () => {
  const data = localStorage.getItem(TOKEN_KEY);
  return data ? data : null;
};
