import { Form, Input, Button, Checkbox, Card } from 'antd';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { isLogin, login } from '../service/login';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 8 },
};

export interface ILogin {
  partnerId: string;
  token: string;
}
export interface routeProps extends RouteComponentProps {}
const Login = (props: routeProps) => {
  useEffect(() => {
    console.log('user is authenticated :', isLogin());
    isLogin() && props.history.push('/');
  }, []);
  const onFinish = (values: ILogin) => {
    console.log('Success:', values);
    login(values);
    props.history.push('/');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Card>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Partner ID"
          name="partnerId"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Token"
          name="token"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Login;
