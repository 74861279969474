import React, { useState } from 'react';
import { Modal, Checkbox, Col, Row, Alert, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { csvConvertKey, csvParseAndDownload } from '../utils/csv';

interface DownloadCsvModalProps {
  data: object[];
}
const DownloadCsvModal = (props: DownloadCsvModalProps) => {
  console.log('REceived props : ', props);
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const { data } = props;

  const [csvColumns, setCsvColumns] = useState<string[]>(
    Object.keys(data[0] ? data[0] : [])
  );

  const submitCSVDownload = () => {
    setIsShowing(false);
    csvParseAndDownload(data, csvColumns);
  };
  const onChange = (checkedValues: string[]) => {
    // console.log('checked values: ', checkedValues);
    setCsvColumns(checkedValues);
  };
  return (
    <>
      <Row justify="start">
        <Button onClick={() => setIsShowing(true)}>Export To CSV</Button>
      </Row>
      <Modal
        title="Choose columns to export"
        visible={isShowing}
        onCancel={() => setIsShowing(false)}
        // onCancel={() => setCsvModal(false)}
        onOk={submitCSVDownload}
        width={600}
      >
        {data && Object.keys(data).length > 0 ? (
          <>
            <Alert
              message={`You will export ${data.length} rows to CSV`}
              type="info"
              showIcon
            />
            <Checkbox.Group
              value={csvColumns}
              style={{ width: '100%', marginTop: '10px' }}
              onChange={(e: any) => onChange(e)}
            >
              <Row>
                {Object.keys(data[0]).map((key) => (
                  <Col span={12}>
                    <Checkbox value={key}>{csvConvertKey(key)}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </>
        ) : (
          <span>No column</span>
        )}
      </Modal>
    </>
  );
};

export default DownloadCsvModal;
