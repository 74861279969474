import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Space,
  Spin,
  Table,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DownloadCsvModal from '../components/downloadCsvModal';
import { isLogin, loadCredentials, logout } from '../service/login';
import { routeProps } from './login';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

export interface IpartnerLeadsData {
  registrationDate: Date;
  tags: string[];
  custom_domain_url: string;
}
const { RangePicker } = DatePicker;

const Homepage = (props: routeProps) => {
  const [data, setData] = useState<IpartnerLeadsData[]>([]);
  const [filteredData, setFilteredData] = useState<
    IpartnerLeadsData[] | undefined
  >(undefined);
  const [dataWithTableFilters, setDataWithTableFilters] = useState<
    IpartnerLeadsData[] | undefined
  >(undefined);
  const [exportData, setExportData] = useState<IpartnerLeadsData[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);

  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<any>('');
  useEffect(() => {
    if (!isLogin()) {
      props.history.push('/login');
      return;
    }
    // get current user id & token
    const credentials = loadCredentials();
    // call api
    fetch('https://aff-rep.com/partner-leads', {
      method: 'POST',
      body: credentials,
      headers: {
        'Content-Type': ' application/json',
      },
    })
      .then((res) => res.json())
      .then((jsonRes) => {
        if (jsonRes.status === 200) {
          console.log('API RESPONSE:', jsonRes);
          setDataWithTableFilters(jsonRes.data);
          setData(jsonRes.data);
          setExportData(jsonRes.data);
        } else if (jsonRes.status === 401) {
          message.error('Invalid Token');
        } else {
          message.error('Something went wrong. Try again');
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleLogOut = () => {
    logout();
    props.history.push('/login');
  };

  const onRangeChange = (range: any) => {
    // filter data

    const arrayToFilter =
      dataWithTableFilters !== data ? dataWithTableFilters : data;
    const filterData = arrayToFilter?.filter(
      (lead: IpartnerLeadsData) =>
        moment(lead.registrationDate).unix() > range[0].unix() &&
        moment(lead.registrationDate).unix() < range[1].unix()
    );
    setFilteredData(filterData);
    setExportData(filterData);
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '';
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
    setDataWithTableFilters(data);
  };

  const columns = [
    {
      title: 'Registration Date',
      width: 200,
      dataIndex: 'registrationDate',
      ellipsis: true,
      key: 'registrationDate',
      render: (row: Date) => <>{moment(row).format('DD-MMMM-YYYY')}</>,
      sorter: (a: IpartnerLeadsData, b: IpartnerLeadsData) =>
        moment(a.registrationDate).unix() - moment(b.registrationDate).unix(),
    },
    {
      title: 'Tags',
      width: 200,
      dataIndex: 'tags',
      key: 'tags',
      ...getColumnSearchProps('tags'),
    },
    {
      title: 'URL',
      ellipsis: true,
      dataIndex: 'custom_domain_url',
      key: 'custom_domain_url',
      ...getColumnSearchProps('custom_domain_url'),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row justify="end">
        <Button onClick={handleLogOut}>Logout</Button>
      </Row>
      {data && exportData && (
        <Card>
          <Row align="middle" justify="start">
            <Col>
              Pick a Range:
              <RangePicker
                style={{ marginLeft: 10 }}
                onChange={onRangeChange}
                allowClear={false}
              />
            </Col>
            {Object.keys(data).length > 1 && (
              <Col offset={1}>
                <DownloadCsvModal data={exportData} />
              </Col>
            )}
          </Row>
          <Alert
            message={`Total Leads : ${exportData.length}`}
            type="info"
            style={{ marginTop: 10 }}
          />
          <Table
            style={{ marginTop: 10 }}
            dataSource={filteredData ? filteredData : data}
            columns={columns}
            pagination={{ pageSize: 10 }}
            onChange={(pagination, filters, sorter, extra) => {
              setExportData(extra.currentDataSource);
              setDataWithTableFilters(extra.currentDataSource);
            }}
          />
        </Card>
      )}
    </Spin>
  );
};

export default Homepage;
