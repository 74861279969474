// import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';

import './App.css';
import Homepage from './pages/Homepage';
import Login from './pages/login';
import 'antd/dist/antd.css';
import { isLogin } from './service/login';
const App = () => {
  // const [loadingCredentials, setLoadingCredentials] = useState<boolean>(true);
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  useEffect(() => {
    setAuthenticated(isLogin());
    // setLoadingCredentials(false);
    // setAuthenticated(true);
  }, []);

  // if (loadingCredentials) {
  //   return (
  //     <div style={{ textAlign: 'center' }}>
  //       <Spin size="small" />
  //       <p>Loading your stuff...</p>
  //     </div>
  //   );
  // }

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/login" component={Login} />
      </Switch>
    </Router>
  );
};

export default App;
